import {FunctionComponent} from "react";
import {roundNumber} from "../../../utils/EshopUtils";
import {SHOP_ROUTE} from "../../../app";
import {Link} from "react-router-dom";
import {Currency} from "../../../types/Common";

interface ProductProps {
    product: any;
    currency: Currency;
    variation: any;
    cartItem: any;
    children?: any;
    wholesalePrice: boolean;
}

export const CheckoutProduct: FunctionComponent<ProductProps> = ({
                                                                     product,
                                                                     variation,
                                                                     currency,
                                                                     cartItem,
                                                                     wholesalePrice
                                                                 }) => {

    const firstImage = product.images?.data?.length > 0 ? product.images.data[0].attributes : null;

    return (
        <li className="flex py-6 px-4 sm:px-6">
            <Link
                to={"/" + SHOP_ROUTE + product.slug}>
                <div className="flex-shrink-0">
                    <img src={firstImage?.formats ? firstImage.formats.thumbnail.url : firstImage.url}
                         alt={firstImage.alternativeText}
                         className="w-20 h-20 object-contain rounded-md"/>
                </div>
            </Link>

            <div className="ml-6 flex-1 flex flex-col">
                <div className="flex">
                    <div className="min-w-0 flex-1">
                        <Link
                            to={"/" + SHOP_ROUTE + product.slug}>
                            <h4 className="text-sm hover:underline">
                                                                <span
                                                                    className="font-medium text-gray-700 hover:text-gray-800">
                                                                    {product.name}
                                                                </span>
                            </h4>
                        </Link>
                        {
                            variation.volume ?
                                <p className="mt-1 text-sm text-gray-500">{variation.volume.amount} {variation.volume.unit.data.attributes.code} {variation.volume.additionalParameter ?? ''}</p> : null
                        }
                        {variation.color && variation.color.data ?
                            <p className="mt-1 text-sm text-gray-500">{variation.color.data.attributes.name.toLowerCase()}</p> : null
                        }
                    </div>
                </div>
            </div>
            <div className="flex-1 flex-col flex items-end">
                <p className="text-sm font-medium text-gray-900">{roundNumber(wholesalePrice ? variation.price.amountWholesale : variation.price.amount)} {currency.name}</p>
                <p className={"text-gray-400 text-sm"}>cena za kus</p>
                <p className="mt-1 text-sm font-medium text-gray-900">{cartItem.quantity} ks</p>
            </div>
        </li>
    )
}