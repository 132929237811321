import React, {FunctionComponent} from "react";
import {Link} from "react-router-dom";
import {LOGIN_ROUTE_TO, REGISTER_ROUTE_TO} from "../../app";

interface Props {
    titleElement: any;
}

export const NotificationOnlyWholesaleUsers: FunctionComponent<Props> = ({titleElement}) => {
    return (
        <div className={"my-4"}>
            {titleElement ?? null}
            <Link
                to={REGISTER_ROUTE_TO}
                type="button"
                className="font-medium text-btn-main hover:text-btn-main-hover">
                Zaregistrujte sa
            </Link>{' '}alebo
            <Link to={LOGIN_ROUTE_TO} type="button"
                  className="font-medium text-btn-main hover:text-btn-main-hover">{' '}prihláste sa</Link> ako
            firma/živnosť.
        </div>
    )
}
