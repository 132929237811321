import React, {FunctionComponent, useEffect, useState} from "react";
import {Filter} from "../filter/Filter";
import {nanoid} from "nanoid";
import {useSelector} from "react-redux";
import {H2} from "../common/H2";
import {roundNumber} from "../../utils/EshopUtils";

interface FeatureItemProps {
    item: any;
    currency: any;
}

const PREFIX_FROM = "od ";
const DELIMITER = " / ";

const PriceItem: FunctionComponent<FeatureItemProps> = ({item, currency}) => {

    function preparePricePerProduct(amounts: any) {
        return amounts && amounts.map((amount: any) => {
            return (amount.prefixFrom ? PREFIX_FROM : "") + (amount.price.amount < 1 ? roundNumber(amount.price.amount) : amount.price.amount) + currency.name;
        }).join(DELIMITER);
    }

    return (
        <div className={"group bg-price-bg cursor-pointer shadow-md rounded-lg mt-6 lg:mt-4 flex relative z-30"}>
            <div
                className={"transition background-color ease-in-out duration-700 w-2.5 h-auto rounded-tl-md rounded-bl-md group-hover:bg-[#bd7a09]"}/>
            <div className="w-full py-5 px-8 cursor-default">
                <div className="md:flex items-center justify-between font-semibold">
                    <p className="text-xl leading-6 text-gray-800">{item.heading}</p>
                    <p className={"text-lg mt-4 md:mt-0 leading-6 text-gray-800 tracking-wider"}>
                        {preparePricePerProduct(item.amount)}
                    </p>
                </div>
                {item.description ?
                    <p className="my-2 text-base leading-6 text-gray-600">{item.description}</p> : null
                }
            </div>
        </div>
    )
}

interface Props {
    priceList: any;
}

export const PriceList: FunctionComponent<Props> = ({priceList}) => {

    const [filter, setFilter] = useState<any>();

    const currency = useSelector((state: any) => state.shop.currency);

    useEffect(() => {
        setFilter(priceList && priceList.categoryFeatures && priceList.categoryFeatures.length > 0 ? priceList.categoryFeatures[0].category : null);
    }, [priceList]);

    return (
        <>
            <div id={"cennik"}
                 className="p-8 bg-white relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 sm:static pt-20">
                <div className={"text-center mb-8"}>
                    <H2>{priceList?.heading?.heading}</H2>
                    <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                        {priceList?.heading?.subHeading}</p>
                </div>
                <Filter allItems={priceList && priceList.categoryFeatures} setFilter={setFilter}
                        currentFilter={filter}/>
                <div className="relative w-full lg:mt-0 mt-12 md:px-8 lg:grid lg:gap-4 lg:grid-cols-2 lg:grid-rows-3">
                    {priceList && priceList.categoryFeatures && priceList.categoryFeatures.map((category: any) => (
                        category.category === filter ?
                            category.features && category.features.map((item: any) => (
                                <PriceItem currency={currency} key={nanoid()} item={item}/>
                            )) : null
                    ))}
                </div>
            </div>
        </>
    );
}
