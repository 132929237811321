import React, {Fragment, FunctionComponent, useEffect, useState} from 'react';
import {Dialog, Popover, Transition} from '@headlessui/react';
import {nanoid} from "nanoid";
import {calculatePriceWithoutTax, getTotalPrice, isSameNumber, roundNumber} from "../../utils/EshopUtils";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {
    CART_OPEN,
    CHANGE_QUANTITY_OF_ITEM_IN_CART,
    REMOVE_ITEM_FROM_CART,
    SET_ITEMS_CART,
    SET_PRODUCTS
} from "../../redux/actions/action_types";
import _ from "lodash";
import {ShoppingBagIcon, XMarkIcon} from "@heroicons/react/24/solid";
import {useQuery} from "@apollo/client";
import {GQL_SPECIFIC_PRODUCTS} from "../../api/GraphQL";
import {SpinnerLoader} from "../loading/SpinnerLoader";
import {SHOP_ROUTE} from "../../app";
import {classNames, join} from "../../utils";
import {NotificationOnlyWholesaleUsers} from "../notification/NotificationOnlyWholesaleUsers";
import {PRICE_VISIBLE_FOR_WHOLESALE_ONLY} from "../../config";

export const MAX_NUMBER_OF_ITEMS_PER_VARIATION = 10;

export interface ItemProps {
    productID: number,
    variationID: number,
    quantity: number
}

export function getProductsIDFromCart(cart: any): any {
    let idsSet: Set<number> = new Set();
    cart.forEach((item: ItemProps) => idsSet.add(_.isNumber(item.productID) ? item.productID : parseInt(item.productID)));
    return Array.from(idsSet);
}

export function getProductsIDFromProducts(products: any): any {
    let idsSet: Set<number> = new Set();
    products.forEach((item: any) => idsSet.add(_.isNumber(item.id) ? item.id : parseInt(item.id)));
    return Array.from(idsSet);
}

export function checkProductAndVariationToRemove(products: any, cart: any) {
    return cart.map((cartProduct: any) => {
        let productItem = products.find((item: any) => isSameNumber(item.id, cartProduct.productID));

        if (!productItem) {
            return cartProduct;
        }
        const variation = productItem.attributes?.variations?.find((variation: any) => isSameNumber(cartProduct.variationID, variation.id));

        if (!variation || variation.stockQuantity < 1) {
            return cartProduct;
        }
        return null;
    });
}

const Cart: FunctionComponent<any> = () => {

    const [cart, cartIsOpen, wholesalePrice, products, currency] = useSelector((state: any) =>
        [state.shop.cart, state.shop.cartIsOpen, state.shop.wholesalePrice, state.shop.products, state.shop.currency], shallowEqual);

    const [isInitialGetOfCart, setIsInitialGetOfCart] = useState(true);
    const [totalPriceWithTax, setTotalPriceWithTax] = useState<number>(0);

    const dispatch = useDispatch();
    let containsAll = (arr: any, target: any) => target.every((v: any) => arr.includes(v));

    const {
        data,
        loading,
        error,
    } = useQuery(GQL_SPECIFIC_PRODUCTS, {
        variables: {arrayId: !cart || cart.length === 0 ? null : getProductsIDFromCart(cart)},
        fetchPolicy: "no-cache",
        skip: isInitialGetOfCart
    });

    useEffect(() => {
        if (data) {
            const productsFromCartToRemove = checkProductAndVariationToRemove(data.products.data, cart);
            if (productsFromCartToRemove?.length > 0) {
                dispatch({
                    type: SET_ITEMS_CART,
                    cart: cart.filter((product: any) => !productsFromCartToRemove.includes(product))
                });
            }
            dispatch({type: SET_PRODUCTS, products: data.products.data});
        }
    }, [data]);

    useEffect(() => {
        if (products) {
            setTotalPriceWithTax(getTotalPrice(cart, products, wholesalePrice));
        }
    }, [cart, products, wholesalePrice]);

    useEffect(() => {
        if (!isInitialGetOfCart) {
            localStorage.setItem('cart', JSON.stringify(cart));
        }
    }, [cart]);

    useEffect(() => {
        const cartSerialized = localStorage.getItem('cart');
        const cartFromLocalStorage = cartSerialized ? JSON.parse(cartSerialized) : [];
        dispatch({type: SET_ITEMS_CART, cart: cartFromLocalStorage});
        setIsInitialGetOfCart(false);

        window.addEventListener('storage', (e) => {
            if (e.newValue) {
                dispatch({type: SET_ITEMS_CART, cart: JSON.parse(e.newValue)});
            }
        });
        return () => {
            window.removeEventListener('storage', (e) => {
            });
        };
    }, []);

    function getNumberOfProducts() {
        if (PRICE_VISIBLE_FOR_WHOLESALE_ONLY && !wholesalePrice) {
            return 0;
        }

        let nbmOfProducts = 0;

        cart.forEach((value: any) => {
            nbmOfProducts += value.quantity;
        });
        return nbmOfProducts;
    }

    function cartOpenStateChange(cartOpenState: boolean) {
        dispatch({
            type: CART_OPEN,
            cartIsOpenStateTo: cartOpenState,
        })
    }

    function isCartCheckoutAllowed() {
        return cart && cart.length > 0 && (!PRICE_VISIBLE_FOR_WHOLESALE_ONLY || wholesalePrice);
    }

    return (
        <Popover>
            <div className={"relative w-7 h-7 cursor-pointer"} onClick={() => cartOpenStateChange(true)}>
                <ShoppingBagIcon className={"w-7 h-7 text-gray-700"}/>
                <span
                    className={"text-[0.7rem] text-white px-[0.35rem] absolute ml-[0.85rem] mt-[-2.2rem] bg-btn-main rounded-full"}>{getNumberOfProducts()}</span>
            </div>
            <Transition.Root show={cartIsOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => cartOpenStateChange(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-500"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-500"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-60 transition-opacity"/>
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-hidden">
                        <div className="absolute inset-0 overflow-hidden">
                            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                                <Transition.Child
                                    as={Fragment}
                                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                                    enterFrom="translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                                    leaveFrom="translate-x-0"
                                    leaveTo="translate-x-full"
                                >
                                    <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                        <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                                            <div className="flex-1 overflow-y-auto py-6 px-4 sm:px-6">
                                                <div className="flex items-start justify-between">
                                                    <Dialog.Title
                                                        className="text-lg font-bold text-gray-900">Nákupný
                                                        košík</Dialog.Title>
                                                    <div className="ml-3 flex h-7 items-center">
                                                        <button
                                                            type="button"
                                                            className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                                                            onClick={() => cartOpenStateChange(false)}
                                                        >
                                                            <span className="sr-only">Close panel</span>
                                                            <XMarkIcon className="w-7 h-7 text-gray-700"
                                                                       aria-hidden="true"/>
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="mt-8">
                                                    <div className="flow-root">
                                                        <ul className="-my-6 divide-y divide-gray-200">
                                                            {
                                                                PRICE_VISIBLE_FOR_WHOLESALE_ONLY && !wholesalePrice ?
                                                                    <NotificationOnlyWholesaleUsers
                                                                        titleElement={<p>Košík je dostupný len pre
                                                                            firemných zákazníkov.</p>}/> :

                                                                    cart && cart.length > 0 && products ?
                                                                        cart.map((cartItem: any) => {
                                                                            let productData = products.find((product: any) => isSameNumber(product.id, cartItem.productID));

                                                                            if (!productData) {
                                                                                return null;
                                                                            }

                                                                            const product = productData.attributes;
                                                                            const variation = product && product.variations.find((variation: any) => isSameNumber(variation.id, cartItem.variationID));

                                                                            return (
                                                                                <li key={nanoid()}
                                                                                    className="flex py-6">
                                                                                    <Link
                                                                                        to={"/" + SHOP_ROUTE + product.slug}
                                                                                        onClick={() => {
                                                                                            cartOpenStateChange(false);
                                                                                        }}>
                                                                                        <div
                                                                                            className="flex h-24 w-24 justify-center items-center flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                                                                                            <img
                                                                                                src={product.images.data[0].attributes.url}
                                                                                                alt={product.images.data[0].attributes.alternativeText}
                                                                                                className="h-16 w-16 object-contain object-center "
                                                                                            />
                                                                                        </div>
                                                                                    </Link>

                                                                                    <div
                                                                                        className="ml-4 flex flex-1 flex-col">
                                                                                        <div>
                                                                                            <div
                                                                                                className="flex flex-row text-base font-medium text-gray-900">
                                                                                                <Link
                                                                                                    className={"mr-auto max-w-[70%]"}
                                                                                                    to={"/" + SHOP_ROUTE + product.slug}
                                                                                                    onClick={() => {
                                                                                                        cartOpenStateChange(false);
                                                                                                    }}>
                                                                                                    <h3 className={"hover:underline"}>
                                                                                                        <span>{product.name}</span>
                                                                                                    </h3>
                                                                                                </Link>
                                                                                                <p className="ml-auto">{roundNumber(wholesalePrice ? variation.price.amountWholesale : variation.price.amount)} {currency.name}</p>
                                                                                            </div>
                                                                                            <div
                                                                                                className="flex flex-1 items-end justify-between text-sm">
                                                                                                <p className="mt-1 text-sm text-gray-500">
                                                                                                    {join([join([variation.volume?.amount, variation.volume?.unit?.data?.attributes?.code, variation.volume?.additionalParameter], ' '), variation.color?.data?.attributes?.name], ', ')}</p>
                                                                                                <p className={"mt-1 text-sm text-gray-500"}>cena
                                                                                                    za kus</p>
                                                                                            </div>

                                                                                        </div>
                                                                                        <div
                                                                                            className="h-12 flex flex-auto items-end justify-between text-sm">
                                                                                            {variation && variation.stockQuantity > 0 ?
                                                                                                <select
                                                                                                    value={cartItem.quantity}
                                                                                                    onChange={(e) => {
                                                                                                        dispatch({
                                                                                                            type: CHANGE_QUANTITY_OF_ITEM_IN_CART,
                                                                                                            productID: cartItem.productID,
                                                                                                            variationID: cartItem.variationID,
                                                                                                            quantity: parseInt(e.target.value)
                                                                                                        });
                                                                                                        let event: any = new Event('storage');
                                                                                                        window.dispatchEvent(event);
                                                                                                    }}
                                                                                                    key={nanoid()}
                                                                                                    id="quantity"
                                                                                                    name="quantity"
                                                                                                    className="cursor-pointer h-[70%] focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-2 border-transparent bg-gray-200 text-gray-600 sm:text-sm rounded-md">
                                                                                                    {[...Array(variation.stockQuantity > 9 ? MAX_NUMBER_OF_ITEMS_PER_VARIATION : variation.stockQuantity)].map((val: any, index: number) =>
                                                                                                        <option
                                                                                                            key={nanoid()}
                                                                                                            value={index + 1}>{index + 1} ks</option>
                                                                                                    )}
                                                                                                </select> : null
                                                                                            }
                                                                                            <div className="flex">
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="font-medium text-btn-main hover:text-btn-main-hover"
                                                                                                    onClick={() => {
                                                                                                        dispatch({
                                                                                                            type: REMOVE_ITEM_FROM_CART,
                                                                                                            productID: cartItem.productID,
                                                                                                            variationID: cartItem.variationID
                                                                                                        });
                                                                                                        let event: any = new Event('storage');
                                                                                                        window.dispatchEvent(event);
                                                                                                    }}
                                                                                                >
                                                                                                    Odstrániť
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        })
                                                                        :
                                                                        <p>Košík je prázdny.</p>
                                                            }
                                                        </ul>
                                                        {loading && !containsAll(getProductsIDFromProducts(products), getProductsIDFromCart(cart)) ?
                                                            <SpinnerLoader
                                                                classname={"w-12 h-12 mt-10 mx-auto"}/>
                                                            : null}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
                                                {wholesalePrice ?
                                                    <div className="text-center text-sm font-medium text-gray-500 mb-4">
                                                        <p>Nakupujete za veľkoobchodné ceny.</p>
                                                    </div> : null

                                                }
                                                <div
                                                    className="flex justify-between text-sm font-medium text-gray-500">
                                                    <p>Celková cena bez DPH</p>
                                                    <p>{roundNumber(calculatePriceWithoutTax(totalPriceWithTax))} {currency.name}</p>
                                                </div>
                                                <div
                                                    className="flex justify-between text-sm mt-0.5 font-medium text-gray-500">
                                                    <p>DPH</p>
                                                    <p>{roundNumber(totalPriceWithTax - calculatePriceWithoutTax(totalPriceWithTax))} {currency.name}</p>
                                                </div>
                                                <div
                                                    className="flex justify-between text-base mt-1.5 font-bold text-gray-900">
                                                    <p>Celková cena vrátane DPH</p>
                                                    <p>{roundNumber(totalPriceWithTax)} {currency.name}</p>
                                                </div>
                                                <div className="mt-6">
                                                    <Link
                                                        onClick={(e) => {
                                                            if (!isCartCheckoutAllowed()) {
                                                                e.preventDefault();
                                                                return;
                                                            }
                                                            cartOpenStateChange(false);
                                                        }}
                                                        to={"/pokladna"}
                                                        className={classNames("flex items-center justify-center rounded-md border border-transparent px-6 py-3 text-base font-medium text-white shadow-sm", isCartCheckoutAllowed() ? "bg-btn-main hover:bg-btn-main-hover" : "cursor-default bg-gray-500 hover:bg-gray-500")}
                                                    >
                                                        K pokladni
                                                    </Link>
                                                </div>
                                                <div
                                                    className="mt-6 flex justify-center text-center text-sm text-gray-500">
                                                    <p>
                                                        alebo{' '}
                                                        <button
                                                            type="button"
                                                            className="font-medium text-btn-main hover:text-btn-main-hover"
                                                            onClick={() => cartOpenStateChange(false)}
                                                        >
                                                            Pokračujte v nákupe<span aria-hidden="true"> &rarr;</span>
                                                        </button>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </Popover>
    )
}

export default Cart;