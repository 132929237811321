import React, {FunctionComponent} from "react";
import tw from "twin.macro";
import styled from "@emotion/styled";
import {nanoid} from "nanoid";

interface Props {
    allItems: any;
    setFilter: any;
    currentFilter: any;
}

const ButtonFilter = tw.button`
     inline-block
     p-4
     rounded-t-lg
     border-b-2
     border-transparent
     hover:text-gray-600
     hover:border-gray-300
     active:text-btn-main
     active:border-btn-main
`

const ButtonFilterFinal = styled(ButtonFilter)<{ isActive: boolean }>`
  color: ${p => p.isActive ? "#bf8a31" : "inherit"};
  border-color: ${p => p.isActive ? "#bf8a31" : "inherit"};

  :hover {
    color: ${p => p.isActive ? "#bf8a31" : "rgb(75 85 99)"};
    border-color: ${p => p.isActive ? "#bf8a31" : "rgb(209 213 219)"};
  }
`

export const Filter: FunctionComponent<Props> = ({allItems, setFilter, currentFilter}) => {
    return (
        <div
            className="text-sm font-medium text-center text-gray-500 border-b border-transparent sm:border-gray-200 max-w-7xl mx-auto flex justify-center">
            <ul className="flex flex-wrap -mb-px justify-center list-none">
                {allItems && allItems.map((itemFilter: any) => (
                    <li key={nanoid()} className="mr-2">
                        <ButtonFilterFinal key={nanoid()} isActive={itemFilter.category === currentFilter}
                                           onClick={() => {
                                               setFilter(itemFilter.category)
                                           }}>{itemFilter.category}</ButtonFilterFinal>
                    </li>
                ))}
            </ul>
        </div>
    );
}
