import {Filter} from "../filter/Filter";
import tw from "twin.macro";
import {FunctionComponent, useEffect, useState} from "react";
import {nanoid} from "nanoid";
import {GalleryTypes} from "../../types/gallery-types";
import {ImageSkeletonLoader} from "../skeleton/ImageSkeletonLoader";
import {VideoSkeletonLoader} from "../skeleton/VideoSkeletonLoader";
import {H2} from "../common/H2";

const RowWrapper = tw.div`
  flex
  flex-row
  flex-wrap
  w-full
  md:w-1/2
`

const ImgWrapperHalf = tw.div`
  w-1/2
  p-1
  md:p-2
`

const ImgWrapperFull = tw.div`
  w-full
  p-1
  md:p-2
`


function prepareItems(itemList: any, filter: any, limit: number, type: GalleryTypes) {

    if (!itemList) {
        return;
    }

    itemList = itemList.categoryItems;
    let itemsOfGivenCategory;

    for (let i = 0; i < itemList.length; i++) {
        if (itemList[i] && itemList[i].category === filter) {
            itemsOfGivenCategory = itemList[i];
            break;
        }
    }

    if (!itemsOfGivenCategory) {
        return;
    }

    let indexOfFullItem: number | undefined = 2;
    let addOne: boolean = true;
    let finalItems: any = [];

    if (type === GalleryTypes.IMAGE || type === GalleryTypes.BOTH) {
        let objectWithItems = prepareItemsOfType(itemsOfGivenCategory.images, 2, false, GalleryTypes.IMAGE);
        finalItems.push.apply(finalItems, objectWithItems.finalItems);
        addOne = objectWithItems.addOne;
        indexOfFullItem = objectWithItems.indexOfFullItem;
    }

    if (type === GalleryTypes.VIDEO || type === GalleryTypes.BOTH) {
        let objectWithItems = prepareItemsOfType(itemsOfGivenCategory.videos, indexOfFullItem, addOne, GalleryTypes.VIDEO);
        finalItems.push.apply(finalItems, objectWithItems.finalItems);
    }

    return finalItems;
}

function prepareItemsOfType(itemsOfGivenCategory: any, indexOfFullImg: number, shouldBeFullFirstItemOfArray: boolean, type: GalleryTypes) {
    const finalItems: any = [];

    for (let i = 0; i < itemsOfGivenCategory.data.length; i++) {
        if ((i % 3) === 0) {
            finalItems.push([]);

            indexOfFullImg = shouldBeFullFirstItemOfArray ? i : i + 2;
            shouldBeFullFirstItemOfArray = !shouldBeFullFirstItemOfArray;
        }

        let isFull = i === indexOfFullImg;
        finalItems[finalItems.length - 1].push({
            src: itemsOfGivenCategory.data[i],
            isFull: isFull,
            type: type
        });
    }

    return {finalItems, indexOfFullItem: indexOfFullImg, addOne: shouldBeFullFirstItemOfArray};
}

const ImgItem: FunctionComponent<any> = ({item, isFull}) => {

    const [loaded, setLoaded] = useState(false);

    //TODO finish skeleton for other devices
    const img = <img
        className={"object-cover object-center w-full h-full max-h-[35rem] rounded-lg " + (!loaded ? "hidden" : "block")}
        src={item.attributes.url} alt={item.attributes.alternativeText}
        onLoad={() => setLoaded(true)}/>;

    const skeleton = !loaded ? <ImageSkeletonLoader isFull={isFull}/> : null;

    return (
        isFull ?
            <ImgWrapperFull>
                {skeleton}
                {img}
            </ImgWrapperFull> :
            <ImgWrapperHalf>
                {skeleton}
                {img}
            </ImgWrapperHalf>
    )
}

interface CheckBoxProps {
    checkbox: any;
    setType: any;
    currentType: any;
    thisType: any;
    classname?: string;
}

const CheckBox: FunctionComponent<CheckBoxProps> = ({checkbox, classname, setType, currentType, thisType}) => {

    let [checked, setChecked] = useState(true);

    return (
        <div className={"flex items-start" + (classname ? (" " + classname) : "")}>
            <div className="flex items-center h-5">
                <input readOnly={true} checked={checked} id={checkbox.name} name={checkbox.name} type="checkbox"
                       className=" text-btn-main accent-btn-main focus:ring-btn-main h-4 w-4 border-gray-300  rounded"
                       onClick={() => {
                           const checkNew = !checked;
                           if (checkNew) {
                               if (currentType === GalleryTypes.NONE) {
                                   setType(thisType);
                               } else if (currentType !== thisType) {
                                   setType(GalleryTypes.BOTH)
                               }
                           } else {
                               if (currentType === thisType) {
                                   setType(GalleryTypes.NONE);
                               } else if (currentType === GalleryTypes.BOTH) {
                                   setType(thisType === GalleryTypes.IMAGE ? GalleryTypes.VIDEO : GalleryTypes.IMAGE)
                               }
                           }
                           setChecked(checkNew);
                       }}/>
            </div>
            <div className="ml-3 text-sm">
                <label htmlFor={checkbox.name} className="font-medium text-gray-700">{checkbox.name}</label>
            </div>
        </div>
    )
}

const VideoItem: FunctionComponent<any> = ({item}) => {

    const [loaded, setLoaded] = useState(false);

    const skeleton = !loaded ? <VideoSkeletonLoader isFull={true}/> : null;

    return (
        <ImgWrapperFull>
            {skeleton}
            <video
                className={" block object-cover object-center w-full h-full max-h-[35rem] rounded-lg " + (!loaded ? "hidden" : "block")}
                controls={true} src={item.attributes.url} title={item} muted={true}
                onLoadedData={() => setLoaded(true)}/>
        </ImgWrapperFull>
    )
}

const NMB_ITEMS_PER_ROW: number = 6;

interface Props {
    galleryItem: any;
}

export const Gallery: FunctionComponent<Props> = ({galleryItem}) => {

    const [limit, setLimit] = useState(NMB_ITEMS_PER_ROW);
    const [filter, setFilter] = useState<any>();
    const [formattedItems, setFormattedItems] = useState<any>();
    const [type, setType] = useState<GalleryTypes>(GalleryTypes.BOTH);

    useEffect(() => {
        setFilter(galleryItem && galleryItem.categoryItems && galleryItem.categoryItems.length > 0 ? galleryItem.categoryItems[0].category : null);
    }, [galleryItem]);

    useEffect(() => {
        setFormattedItems(prepareItems(galleryItem, filter, limit, type));
    }, [filter, limit, galleryItem, type])

    function increaseLimit() {
        setLimit(limit + NMB_ITEMS_PER_ROW);
    }

    return (
        <section id={"galeria"}
                 className="overflow-hidden text-gray-700 pt-12 pb-20 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className={"text-center mb-8"}>
                <H2>{galleryItem?.heading?.heading}</H2>
                <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                    {galleryItem?.heading?.subHeading}</p>
            </div>
            <Filter setFilter={setFilter} allItems={galleryItem && galleryItem.categoryItems}
                    currentFilter={filter}/>

            <div className={"flex justify-center flex-row my-6"}>
                <CheckBox classname={"first:mr-4"} checkbox={{name: "Fotky"}} thisType={GalleryTypes.IMAGE}
                          currentType={type} setType={setType}/>
                <CheckBox checkbox={{name: "Videá"}} thisType={GalleryTypes.VIDEO} currentType={type}
                          setType={setType}/>
            </div>

            <div className="container mt-8 px-5 py-2 mx-auto lg:pt-8 lg:px-32">
                <div className="flex flex-wrap -m-1 md:-m-2">
                    {formattedItems && formattedItems.map((items: any) => (
                        <RowWrapper key={nanoid()}>
                            {items && items.map(((item: any) => (
                                item.type === GalleryTypes.IMAGE ?
                                    <ImgItem key={nanoid()} item={item.src} isFull={item.isFull}/> :
                                    <VideoItem key={nanoid()} item={item.src} isFull={true}></VideoItem>
                            )))}
                        </RowWrapper>
                    ))}
                </div>
            </div>
        </section>
    )
}
