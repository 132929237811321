import tw from "twin.macro";
import {nanoid} from "nanoid";
import {H2} from "../common/H2";

const Link = tw.a`
  text-heading-two
  leading-relaxed
`

const OpeningHoursParagraph = tw.p`
  mt-0.5
  text-[1.07rem]
  text-gray-500
`;

const openingHours = [
    "Pondelok: 9 - 17",
    "Utorok: 9 - 17",
    "Streda: 9 - 17",
    "Štvrtok: 9 - 17",
    "Piatok: 9 - 17",
    "Sobota: 8 - 13",
    "Nedeľa: zavreté"
];

export default function Contact() {
    return (
        <section id={"kontakt"}
                 className="bg-gray-50 pt-10 px-4 rounded-md shadow-md text-gray-600 body-font relative max-w-7xl mx-auto">
            <div className={"text-center mb-16"}>
                <H2>Kontakt</H2>
                <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                    Kontaktné údaje</p>
            </div>
            <div className="px-5 mx-auto flex sm:flex-nowrap flex-wrap">
                <div
                    className="min-h-[36rem] max-h-[50rem] w-full md:w-2/3 bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
                    <iframe className="absolute inset-0"
                            title="map"
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10634.364148181363!2d18.6034825!3d48.2144927!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9d1ac53ee53b485d!2skadern%C3%ADctvo%20Nature!5e0!3m2!1ssk!2ssk!4v1654770674200!5m2!1ssk!2ssk"
                            width="100%" height="100%"></iframe>
                    <div className="bg-white relative flex flex-wrap py-6 rounded shadow-md">
                        <div className="px-6">
                            <p className="title-font font-semibold uppercase text-gray-900 tracking-widest text-xs">Adresa</p>
                            <address className={"pt-2"}>
                                Andreja Sládkoviča 2<br/>
                                934 05 Levice
                            </address>
                        </div>
                        <div className="px-6 mt-4 lg:mt-0">
                            <p className="title-font font-semibold text-gray-900 uppercase tracking-widest text-xs">Email</p>
                            <Link href={"mailto:emiliazelinova@gmail.com"}>emiliazelinova@gmail.com</Link>
                            <p className="title-font font-semibold text-gray-900 uppercase tracking-widest text-xs mt-4">Telefón</p>
                            <a href={"tel:+421948211345"} className="leading-relaxed">0948 211 345</a>
                        </div>
                    </div>
                </div>
                <div className="w-full md:w-1/3 flex flex-col md:ml-auto w-full md:mb-8 mt-8 sm:mt-0">
                    <div className="pb-16 px-4 sm:pl-6 lg:col-span-2 lg:pl-8 lg:mb-8">
                        <div className="mx-auto">
                            <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">Kontaktné
                                informácie</h2>
                            <h3 className="mt-4 mb-2 text-xl font-extrabold tracking-tight text-gray-600 sm:text-2xl">Otváracie
                                hodiny</h3>
                            {openingHours.map((oh) => (
                                <OpeningHoursParagraph key={nanoid()}>{oh}</OpeningHoursParagraph>
                            ))}
                            <h3 className="mt-4 mb-2 text-xl font-extrabold tracking-tight text-gray-600 sm:text-2xl">Detaily
                                objednávok</h3>
                            <p className="mt-3 text-base leading-6 text-gray-500">Otváracie hodiny sú iba orientačné,
                                kaderníctvo funguje na objednávku.</p>
                            <p className="mt-3 text-base leading-6 text-gray-500">Objednávky vybavujem telefonicky od 8
                                - 9.</p>
                            <p className="mt-3 text-base leading-6 text-gray-500">Zložité farbenia, zmeny farby, melíre
                                beriem len v
                                doobedňajších hodinách do 13.</p>
                            <p className="mt-3 text-base leading-6 text-gray-500">V sobotu nefarbím iba robím účesy.</p>
                            <h3 className="mt-4 mb-2 text-xl font-extrabold tracking-tight text-gray-600 sm:text-2xl">Kontaktné
                                údaje</h3>
                            <dl className="mt-2 text-base text-gray-500">
                                <div>
                                    <dt className="sr-only">Postal address</dt>
                                    <dd>
                                        <p>Andreja Sládkoviča 2</p>
                                        <p> 934 05 Levice</p>
                                    </dd>
                                </div>
                                <div className="mt-6">
                                    <dt className="sr-only">Telefónne číslo</dt>
                                    <dd className="flex">
                                        <svg className="flex-shrink-0 h-6 w-6 text-gray-400"
                                             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             stroke="currentColor" aria-hidden="true">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                  d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"/>
                                        </svg>
                                        <a href="tel:+421948211345" className="no-underline"><span className="ml-3"> +421 948 211 345</span></a>
                                    </dd>
                                </div>
                                <div className="mt-3">
                                    <dt className="sr-only">Email</dt>
                                    <dd className="flex">
                                        <svg className="flex-shrink-0 h-6 w-6 text-gray-400"
                                             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             stroke="currentColor" aria-hidden="true">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
                                        </svg>
                                        <a href="mailto:emiliazelinova@gmail.com" className="no-underline"><span
                                            className="ml-3"> emiliazelinova@gmail.com </span></a>
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
