import tw from "twin.macro";
import {FunctionComponent} from "react";

const Link = tw.a`
  inline-block
  text-center
  bg-btn-main
  border
  mt-8
  border-transparent
  rounded-md
  py-3
  px-8
  font-medium
  text-white
  hover:bg-btn-main-hover
`

interface Props {
    main: any;
}

export const MainHeaderImageCarousel: FunctionComponent<Props> = ({main}) => {

    const images = main.images.data;

    return (
        <div className="relative bg-white overflow-hidden mt-2">
            <div className="pt-20 pb-[34rem] sm:pt-24 sm:pb-80 lg:pt-56 lg:pb-56">
                <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 sm:static">
                    <div className="sm:max-w-lg">
                        <h1 className="text-4xl font font-extrabold tracking-tight text-gray-900 sm:text-6xl">
                            {main.header}
                        </h1>
                        <p className="mt-4 text-xl text-gray-500">
                            {main.description}
                        </p>
                    </div>
                    <Link href={main.button.link}>
                        {main.button.nameEnabled}
                    </Link>
                    <div>
                        <div className="mt-10">
                            <div
                                aria-hidden="true"
                                className="pointer-events-none lg:absolute lg:inset-y-0 lg:max-w-7xl lg:mx-auto lg:w-full"
                            >
                                <div
                                    className="absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8 lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8">
                                    <div className="flex items-center space-x-6 lg:space-x-8">
                                        <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                                            {images?.[0] ?
                                                <div
                                                    className="w-44 h-64 rounded-lg overflow-hidden sm:opacity-0 lg:opacity-100">
                                                    <img
                                                        src={images[0].attributes.url}
                                                        alt={images[0].attributes.alternativeText}
                                                        className="w-full h-full object-center object-cover"
                                                    />
                                                </div> : null
                                            }
                                            {
                                                images?.[1] ?
                                                    <div className="w-44 h-64 rounded-lg overflow-hidden">
                                                        <img
                                                            src={images[1].attributes.url}
                                                            alt={images[1].attributes.alternativeText}
                                                            className="hidden sm:block w-full h-full object-center object-cover"
                                                        />
                                                    </div> : null
                                            }
                                        </div>
                                        <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                                            <div className="w-44 h-64 rounded-lg overflow-hidden">
                                                {
                                                    images?.[2] ?
                                                        <div className="w-44 h-64 rounded-lg overflow-hidden">
                                                            <img
                                                                src={images[2].attributes.url}
                                                                alt={images[2].attributes.alternativeText}
                                                                className="w-full h-full object-center object-cover"
                                                            />
                                                        </div> : null
                                                }
                                            </div>
                                            <div className="w-44 h-64 rounded-lg overflow-hidden">
                                                {
                                                    images?.[3] ?
                                                        <div className="w-44 h-64 rounded-lg overflow-hidden">
                                                            <img
                                                                src={images[3].attributes.url}
                                                                alt={images[3].attributes.alternativeText}
                                                                className="w-full h-full object-center object-cover"
                                                            />
                                                        </div> : null
                                                }
                                            </div>
                                            <div className="w-44 h-64 rounded-lg overflow-hidden">
                                                {
                                                    images?.[4] ?
                                                        <div className="w-44 h-64 rounded-lg overflow-hidden">
                                                            <img
                                                                src={images[4].attributes.url}
                                                                alt={images[4].attributes.alternativeText}
                                                                className="w-full h-full object-center object-cover"
                                                            />
                                                        </div> : null
                                                }
                                            </div>
                                        </div>
                                        <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                                            <div className="w-44 h-64 rounded-lg overflow-hidden">
                                                {
                                                    images?.[5] ?
                                                        <div className="w-44 h-64 rounded-lg overflow-hidden">
                                                            <img
                                                                src={images[5].attributes.url}
                                                                alt={images[5].attributes.alternativeText}
                                                                className="w-full h-full object-center object-cover"
                                                            />
                                                        </div> : null
                                                }
                                            </div>
                                            <div className="w-44 h-64 rounded-lg overflow-hidden">
                                                {
                                                    images?.[6] ?
                                                        <div className="w-44 h-64 rounded-lg overflow-hidden">
                                                            <img
                                                                src={images[6].attributes.url}
                                                                alt={images[6].attributes.alternativeText}
                                                                className="hidden sm:block w-full h-full object-center object-cover"
                                                            />
                                                        </div> : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
