import tw from "twin.macro";
import {FunctionComponent} from "react";
import {nanoid} from "nanoid";
import {H2} from "../common/H2";

const features = [
    {
        name: 'Individuálny prístup',
        description:
            'Máme individuálny prístup ku každému zákazníkovi. Pri rezervácií termínu sa naši kaderníci s vami porozprávajú ohľadne vašich predstáv. Naším cielom je aby ste boli spokojný.',
        iconSrc: "https://res.cloudinary.com/kadernictvo-natur/image/upload/v1654757721/people_group_solid_4f02ebf211.svg?updated_at=2022-06-09T06:55:23.500Z"
    },
    {
        name: 'Najmodernejšie techniky',
        description:
            'Používame najmodernejšie techniky ako sú foilyage, balayage a moderné techniky odfarbovania.',
        iconSrc: "https://res.cloudinary.com/kadernictvo-natur/image/upload/v1654756944/scissors_solid_197207275d.svg?updated_at=2022-06-09T06:42:26.070Z"
    },
    {
        name: 'Bez náhlenia',
        description:
            'Rezervujeme si dostatok času pre každého zákaznika aby sme docielili finálny vzhľad aký očakávate.',
        iconSrc: "https://res.cloudinary.com/kadernictvo-natur/image/upload/v1654757721/clock_solid_2_c9cdb467d2.svg?updated_at=2022-06-09T06:55:23.524Z"
    },
    {
        name: 'Školenia',
        description:
            'Organizujeme pravideľné školenia, na ktorých vyučujeme špeciálne techniky. Taktiež robíme akreditované kurzy pre začínajúcich kaderníkov pod vedením Emílie Zelinovej.',
        iconSrc: "https://res.cloudinary.com/kadernictvo-natur/image/upload/v1654757721/graduation_cap_solid_22086fcb51.svg?updated_at=2022-06-09T06:55:23.439Z"
    }
]

const WrapperIcon = tw.div`
  absolute
  text-white
  bottom-0
  left-0
  bg-icon-bg
  rounded
  w-16
  h-16
  flex
  items-center
  justify-center
  mt-2
  mr-3
`

const WrapperShadow = tw.div`
  absolute
  top-0
  right-0
  bg-icon-bg-light
  rounded
  w-16
  h-16
  mt-2
  mr-1
`

const Item: FunctionComponent<any> = ({item}) => {
    return (
        <div aria-label="card 1" className="focus:outline-none flex sm:w-full md:w-5/12 pb-5 sm:pb-6 md:pb-8 lg:pb-12">
            <div className="w-20 h-20 relative mr-5">
                <WrapperShadow/>
                <WrapperIcon
                    className="absolute text-white bottom-0 left-0 rounded w-16 h-16 flex items-center justify-center mt-2 mr-3">
                    <img src={item.iconSrc} alt="drawer" className={"w-7 h-7"}/>
                </WrapperIcon>
            </div>
            <div className="w-10/12">
                <h3 className="focus:outline-none text-lg font-bold leading-tight text-gray-800">{item.name}</h3>
                <p className="focus:outline-none text-base text-gray-600 leading-normal pt-2">{item.description}</p>
            </div>
        </div>
    )
}

export default function Feature() {
    return (
        <div className="py-16 bg-white">
            <div id={"onas"} className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="text-center">
                    <H2>Viac o nás</H2>
                    <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                        Čo ponúkame
                    </p>
                    <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                        Náš skúsený tím sa snaží vyhovieť každému zákaznikovi.
                    </p>
                </div>

                <div tabIndex={0} aria-label="group of cards"
                     className="focus:outline-none mt-20 flex flex-wrap justify-center gap-10 px-4">
                    {features.map((item: any) => (
                        <Item key={nanoid()} item={item}/>
                    ))}
                </div>
            </div>
        </div>
    )
}