import React, {Fragment, FunctionComponent} from "react";
import {Menu, Transition} from '@headlessui/react';
import {useSelector} from "react-redux";
import {nanoid} from "nanoid";
import {Link, useLocation} from "react-router-dom";
import {ArrowRightOnRectangleIcon, ShoppingBagIcon, UserCircleIcon, UserIcon} from "@heroicons/react/24/solid";
import {LOGIN_ROUTE_TO, ORDER_LIST_ROUTE, REGISTER_ROUTE_TO} from "../../app";

interface Props {
}

export const Profile: FunctionComponent<Props> = () => {

    const user = useSelector((state: any) => state.user);
    const location = useLocation();

    return (
        <Menu as="div" className="relative">
            <div>
                <Menu.Button
                    className="max-w-md flex items-center">
                    <span className="sr-only">Open user menu</span>
                    {!user.jwt ?
                        <UserIcon className={"bg-nav-background text-gray-700 h-7 w-7"}/> :
                        <div
                            className={"flex flex-row gap-1 bg-gray-300 hover:bg-gray-400 bg-opacity-50 hover:bg-opacity-50 shadow-sm items-center rounded-xl p-3"}>
                            <UserCircleIcon className={"text-gray-700 h-7 w-7"}/>
                            <p className={"hidden md:block font-sans font-medium text-gray-800"}>{user.firstName.value} {user.lastName.value}</p>
                        </div>
                    }
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    className={"z-50 origin-top-right absolute right-0 mt-2 rounded-md shadow-lg break-all py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none " + (user ? "w-56 md:w-80" : "w-52")}>
                    {
                        !user.jwt ?
                            <>
                                <Menu.Item key={nanoid()}>
                                    {() => (
                                        <Link
                                            to={LOGIN_ROUTE_TO} state={{previousPath: location.pathname}}
                                            className={'block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'}
                                        >
                                            {"Prihlásiť sa"}
                                        </Link>
                                    )}
                                </Menu.Item>
                                <Menu.Item key={nanoid()}>
                                    {() => (
                                        <Link
                                            to={REGISTER_ROUTE_TO}
                                            className={'block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'}
                                        >
                                            {"Zaregistrovať sa"}
                                        </Link>
                                    )}
                                </Menu.Item>
                            </> :
                            <>
                                <Menu.Item key={nanoid()}>
                                    <>
                                        <div className={"block px-4 py-2 text-sm text-gray-700"}>
                                            Vitajte, <span
                                            className={"font-bold"}>{user.firstName.value} {user.lastName.value}</span>
                                        </div>
                                    </>
                                </Menu.Item>
                                <Menu.Item key={nanoid()}>
                                    {() => (
                                        <Link
                                            to={"/profil"}
                                            className={'flex gap-1 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex-row items-center'}
                                        >
                                            <UserCircleIcon className={"h-5 w-5"}/>
                                            {"Profil"}
                                        </Link>
                                    )}
                                </Menu.Item>
                                <Menu.Item key={nanoid()}>
                                    {() => (
                                        <Link
                                            to={"/" + ORDER_LIST_ROUTE}
                                            className={'flex gap-1 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex-row items-center'}
                                        >
                                            <ShoppingBagIcon className={"h-5 w-5"}/>
                                            {"Objednávky"}
                                        </Link>
                                    )}
                                </Menu.Item>
                                <Menu.Item key={nanoid()}>
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.localStorage.removeItem('jwt');
                                            window.location.reload();
                                            //dispatch({type: LOGOUT_USER});
                                        }}
                                        className={'flex gap-1 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full h-full flex-row items-center'}
                                    >
                                        <ArrowRightOnRectangleIcon className={"h-5 w-5"}/>
                                        Odhlásiť sa
                                    </button>
                                </Menu.Item>
                            </>
                    }
                </Menu.Items>
            </Transition>
        </Menu>
    )
}