import tw from "twin.macro";
import {FunctionComponent} from "react";
import {nanoid} from "nanoid";
import {H2} from "../common/H2";

const MainWrapper = tw.div`
  w-full
  h-full
  bg-team-bg
  rounded-md
  shadow-md
  pb-4
  sm:pb-8
  md:pb-12
`

const Profile: FunctionComponent<any> = ({profile}) => {
    return (
        <div className="xl:w-1/3 sm:w-3/4 md:w-2/5 relative mt-16 mb-32 sm:mb-24 xl:max-w-sm lg:w-2/5">
            <div className="rounded overflow-hidden shadow-md bg-white">
                <div className="absolute -mt-20 w-full flex justify-center">
                    <div className="h-32 w-32">
                        <img title={profile.fullName} alt={profile.photo.data.attributes.alternativeText}
                             src={profile.photo.data.attributes.url}
                             className="rounded-full object-cover h-full w-full shadow-md"/>
                    </div>
                </div>
                <div className="px-6 mt-16 min-h-[18rem]">
                    <div className="font-bold text-3xl text-center pb-1">{profile.fullName}</div>
                    <p className="text-gray-800 text-md text-center">{profile.organization}</p>
                    <p className="text-center text-base pt-3 font-normal text-gray-600 leading-normal">{profile.description}</p>
                    {
                        profile.socialLinks && profile.socialLinks.length > 0 ?
                            <div className="w-full flex justify-center pt-5 pb-5">
                                {
                                    profile.socialLinks.map((socialLink: any) =>
                                        <a key={nanoid()} href={socialLink.link} className="mx-5">
                                            <img className={"w-6 h-6"} src={socialLink.icon.data.attributes.url}
                                                 alt={socialLink.icon.data.attributes.alternativeText}/>
                                        </a>
                                    )
                                }
                            </div>
                            : null
                    }
                </div>
            </div>
        </div>
    )
}

interface Props {
    item: any;
}

export const Team: FunctionComponent<Props> = ({item}) => {
    return (
        <div id={"kadernici"} className={"max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"}>
            <MainWrapper>
                <div className="container flex justify-center mx-auto pt-16">
                    <div className={"text-center"}>
                        <H2>{item?.heading?.heading}</H2>
                        <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                            {item?.heading?.subHeading}</p>
                        {
                            item?.heading?.description ?
                                <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                                    {item.heading.description}
                                </p> : null
                        }
                    </div>
                </div>
                <div className="w-full px-10 pt-10">
                    <div className="container mx-auto">
                        <div
                            className="sm:flex items-center sm:justify-around flex-wrap">
                            {item && item.profiles.map((profile: any) => (
                                <Profile key={nanoid()} profile={profile}/>
                            ))}
                        </div>
                    </div>
                </div>
            </MainWrapper>
        </div>
    )
}
