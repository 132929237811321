import React, {Fragment, FunctionComponent, useState} from 'react';
import {Dialog, RadioGroup, Transition} from '@headlessui/react';
import {nanoid} from "nanoid";
import {NO_COLOR, prepareVariations} from "../../../utils/EshopUtils";
import {CartItemState} from "../../../types/cart-item-state";
import {ProductDatabaseProps} from "./Product";
import {ADD_ITEM_TO_CART, SET_CART_ITEM_STATE} from "../../../redux/actions/action_types";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import {Link} from "react-router-dom";
import {SHOP_ROUTE} from "../../../app";
import {XMarkIcon} from "@heroicons/react/24/solid";
import {VolumeBox} from "./parts/VolumeBox";
import {ColorBox} from "./parts/ColorBox";
import {SelectedVariationStatus} from "./parts/SelectedVariationStatus";
import {NumberOfUnits} from "./parts/NumberOfUnits";
import {SelectNumberOfProducts} from "./parts/SelectNumberOfProducts";
import {Currency} from "../../../types/Common";
import {AddedItemState} from "./parts/AddedItemState";
import {ContainsProducts} from "./parts/ContainsProducts";
import {CkEditorDiv, classNames} from "../../../utils";
import {PRICE_VISIBLE_FOR_WHOLESALE_ONLY} from "../../../config";
import {NotificationOnlyWholesaleUsers} from "../../notification/NotificationOnlyWholesaleUsers";
import {DocumentIcon} from "@heroicons/react/24/outline";

interface ProductDetailsProps {
    productDetails: ProductDatabaseProps;
    productId: any;
    currency: Currency;
    isOpen: boolean;
    setIsOpen: any;
}

interface ColorOptionProps {
    color: any;
}

export const ColorOption: FunctionComponent<ColorOptionProps> = ({color}) => {

    return (
        <RadioGroup.Option
            key={nanoid()}
            value={color.id}
            className={({checked}) =>
                classNames(
                    checked ? 'ring ring-offset-1 ring-[3-px] ring-btn-main' : '',
                    'relative p-0.5 rounded flex items-center justify-center cursor-pointer focus:outline-none w-14 h-14 m-2'
                )
            }
        >
            <RadioGroup.Label as="span" className="sr-only">
                {color.attributes.name}
            </RadioGroup.Label>
            <div className={"group flex relative"}>
                {

                    color.attributes.img?.data ?
                        <img
                            className={"w-14 h-14 group-hover:border-btn-main rounded group-hover:ring group-hover:ring-offset-1 md:group-hover:ring-[3px] group-hover:ring-btn-main"}
                            src={color.attributes.img.data.attributes.url}
                            alt={color.attributes.img.data.attributes.alternativeText}/>
                        :
                        <div
                            className={'h-14 w-14 group-hover:ring group-hover:ring-offset-1 md:group-hover:ring-[3px] group-hover:ring-btn-main rounded flex justify-center items-center'}>
                            <div
                                aria-hidden="true"
                                style={{background: (color?.attributes?.hexCode !== NO_COLOR ? color.attributes.hexCode : 'transparent')}}
                                className={classNames(
                                    'w-12 h-12 rounded-full'
                                )}
                            />
                        </div>
                }

                <div
                    className="opacity-0 w-24 bg-white text-black border text-center text-xs rounded-lg py-2 absolute z-10 group-hover:opacity-100 top-full left-1/2 -translate-x-1/2 px-3 pointer-events-none">
                    {color.attributes.name}
                </div>
            </div>
        </RadioGroup.Option>
    )
}

const ProductDetailsModal: FunctionComponent<ProductDetailsProps> = ({
                                                                         productDetails,
                                                                         productId,
                                                                         isOpen,
                                                                         setIsOpen,
                                                                         currency
                                                                     }) => {

    const [wholesalePrice, cartItemState] = useSelector((state: any) => [state.shop.wholesalePrice, state.shop.cartItemState], shallowEqual);

    const {variations, variationsOrder} = prepareVariations(productDetails.variations);
    const productDescription = DOMPurify.sanitize(productDetails?.description);

    const [selectedColor, setSelectedColor] = useState<any>(variations ? (Object.keys(variations).length === 1 && variations.hasOwnProperty(NO_COLOR)) ? NO_COLOR : Object.keys(variations)[0] : null);
    const [selectedVariation, setSelectedVariation] = useState(variations[selectedColor][0]);
    const [quantity, setQuantity] = useState<number>(1);

    const dispatch = useDispatch();

    console.log(productDetails);

    if (cartItemState === CartItemState.ADDED_ITEM) {
        return <AddedItemState productDetails={productDetails} selectedVariation={selectedVariation}
                               setIsOpen={setIsOpen} dispatch={dispatch}
                               quantity={quantity}
                               currency={currency}
                               wholesalePrice={wholesalePrice} isOpen={isOpen}/>
    }

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={setIsOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity md:block"/>
                </Transition.Child>
                <div className="fixed z-50 inset-0 overflow-y-auto">
                    <div
                        className="flex items-stretch md:items-center justify-center min-h-full text-center md:px-2 lg:px-4">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                            enterTo="opacity-100 translate-y-0 md:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 md:scale-100"
                            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                        >
                            <Dialog.Panel
                                className="flex text-base text-left transform transition w-full md:max-w-4xl md:px-4 md:my-8 lg:max-w-5xl justify-center">
                                <div
                                    className="w-full relative flex items-center bg-white px-4 pt-14 pb-8 overflow-hidden shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8 rounded-lg">
                                    <button
                                        type="button"
                                        className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
                                        onClick={() => setIsOpen(false)}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon
                                            className="w-7 h-7 text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-btn-main"
                                            aria-hidden="true"/>
                                    </button>

                                    <div className={"w-full flex flex-col"}>
                                        <div
                                            className="w-full grid grid-cols-1 gap-y-8 gap-x-6 items-start sm:grid-cols-12 lg:gap-x-8">
                                            <div
                                                className="mx-auto object-center rounded-lg bg-white overflow-hidden sm:col-span-5">
                                                <img src={productDetails?.images?.data[0]?.attributes.url}
                                                     alt={productDetails?.images?.data[0]?.attributes.alternativeText}
                                                     className="object-center object-contain h-auto w-64 sm:w-72 md:w-80 sm:object-cover"/>
                                            </div>
                                            <div className="sm:col-span-7">
                                                <h2 className="text-2xl font-extrabold text-gray-900 sm:pr-12">{productDetails.name}</h2>
                                                <p className={"mt-4"}>{productDetails.shortDescription}</p>

                                                <section aria-labelledby="options-heading" className="mt-6">
                                                    <h3 id="options-heading" className="sr-only">
                                                        Product options
                                                    </h3>
                                                    <form onSubmit={(e) => {
                                                        e.preventDefault();

                                                        dispatch({
                                                            type: SET_CART_ITEM_STATE,
                                                            cartItemState: CartItemState.ADDED_ITEM,
                                                        });

                                                        dispatch({
                                                            type: ADD_ITEM_TO_CART,
                                                            productID: productId,
                                                            variationID: selectedVariation.id,
                                                            quantity
                                                        });
                                                        let event: any = new Event('storage');
                                                        window.dispatchEvent(event);
                                                    }}>

                                                        {
                                                            !PRICE_VISIBLE_FOR_WHOLESALE_ONLY || wholesalePrice ?
                                                                <>
                                                                    <SelectedVariationStatus
                                                                        selectedVariation={selectedVariation}
                                                                        wholesalePrice={wholesalePrice}
                                                                        currency={currency}/>

                                                                    {/* Colors */}
                                                                    <div className="mt-6">
                                                                        <ColorBox selectedColor={selectedColor}
                                                                                  setSelectedColor={setSelectedColor}
                                                                                  setSelectedVariation={setSelectedVariation}
                                                                                  variations={variations}
                                                                                  variationsOrder={variationsOrder}

                                                                        />
                                                                    </div>

                                                                    {/* Volumes */}
                                                                    <VolumeBox selectedVariation={selectedVariation}
                                                                               wholesalePrice={wholesalePrice}
                                                                               currency={currency}
                                                                               selectedColor={selectedColor}
                                                                               setSelectedVariation={setSelectedVariation}
                                                                               variations={variations}/>

                                                                    <NumberOfUnits
                                                                        selectedVariation={selectedVariation}/>

                                                                    <div className={"flex flex-row mt-6"}>
                                                                        <SelectNumberOfProducts
                                                                            selectedVariation={selectedVariation}
                                                                            quantity={quantity}
                                                                            setQuantity={setQuantity}/>
                                                                        <button
                                                                            disabled={selectedVariation?.stockQuantity < 1}
                                                                            type="submit"
                                                                            className="w-full bg-btn-main border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-btn-main-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-btn-main disabled:bg-gray-500 disabled:cursor-not-allowed"
                                                                        >
                                                                            Pridať do košíka
                                                                        </button>
                                                                    </div>
                                                                </> : <NotificationOnlyWholesaleUsers
                                                                    titleElement={<p>Produkt je dostupný len pre firemných
                                                                        zákazníkov.</p>}/>
                                                        }
                                                    </form>
                                                </section>
                                            </div>
                                        </div>
                                        <section aria-labelledby="information-heading" className="mt-6">
                                            <div className={"flex flex-row gap-6 items-center text-lg"}>
                                                <h3 id="information-heading">
                                                    Informácie o produkte
                                                </h3>

                                                <Link className={"font-sans text-heading-one text-base"}
                                                      to={"/" + SHOP_ROUTE + productDetails.slug}
                                                      state={{
                                                          selectedColor,
                                                          selectedVariation,
                                                          quantity,
                                                          isFromLoadedPage: true
                                                      }}>Detail produktu</Link>
                                            </div>
                                            <CkEditorDiv
                                                className={"mt-1 ml-4 md:ml-0 text-gray-800 text-base leading-5"}>
                                                {parse(productDescription)}
                                            </CkEditorDiv>
                                            <ContainsProducts productDetails={productDetails}/>
                                            {
                                                productDetails?.documents?.data?.length > 0 ?
                                                    <>
                                                        <h3 id="product-manuals" className={"text-lg"}>
                                                            Návody na stiahnutie
                                                        </h3>
                                                        {
                                                            productDetails.documents.data.map((document: any) =>
                                                                <div className={"flex flex-row gap-2 items-center mt-2"}>
                                                                    <DocumentIcon className={"w-6 h-6"}/>
                                                                    <a className={"text-blue-500 hover:underline"}
                                                                       href={document?.attributes?.url}
                                                                       target="_blank">{document.attributes.name}</a>
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                    : null
                                            }
                                        </section>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default ProductDetailsModal;
