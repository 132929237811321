import React, {FunctionComponent} from "react";
import NavFooterWrapperSection from "./NavFooterWrapperSection";
import Feature from "../components/feature/Feature";
import Contact from "../components/contact/Contact";
import {PriceList} from "../components/feature/PriceList";
import {useQuery} from "@apollo/client";
import {GQL_MAIN} from "../api/GraphQL";
import {Gallery} from "../components/gallery/Gallery";
import {Team} from "../components/team/Team";
import {Maintenance} from "../components/error-pages/Maintenance";
import { SectionPageLoader } from "../components/loading/SectionPageLoader";
import {MainHeaderImageCarousel} from "../components/main/MainHeaderImageCarousel";

interface Props {
    className?: string;
}

export const MainSection: FunctionComponent<Props> = () => {

    const {data, loading, error} = useQuery(GQL_MAIN);

    if (error) return <Maintenance/>;

    return (
        <NavFooterWrapperSection>
            {loading ?
                <SectionPageLoader/> :
                error? null :
                <>
                    <MainHeaderImageCarousel main={data?.main?.data?.attributes}/>
                    <Feature/>
                    <Team item={data?.team?.data.attributes}/>
                    <PriceList priceList={data?.priceList?.data.attributes}/>
                    <Gallery galleryItem={data?.gallery?.data.attributes}/>
                    <Contact/>
                </>}
        </NavFooterWrapperSection>
    );
}
