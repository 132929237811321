import 'react-app-polyfill/ie9';
import React, {FunctionComponent} from 'react';
import * as serviceWorker from './serviceWorker';
import {legacy_createStore as createStore} from 'redux'
import {Provider} from 'react-redux'
import reducerMain from "./redux/reducers";
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import {BACKEND_URL} from "./config";
import {ApolloClient, ApolloProvider, InMemoryCache} from "@apollo/client";
import "./output.css"
import {createRoot} from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import {App} from "./app";

const store = createStore(reducerMain);

const client = new ApolloClient({
    uri: BACKEND_URL + "graphql",
    cache: new InMemoryCache()
})

let container: any;

document.addEventListener('DOMContentLoaded', function (event) {
    if (!container) {
        container = document.getElementById('application-root');
        const root = createRoot(container);
        root.render(
            <React.StrictMode>
                <Root/>
            </React.StrictMode>
        );
    }
});

export const Root: FunctionComponent<any> = () => {
    return (
        <ApolloProvider client={client}>
            <Provider store={store}>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
            </Provider>
        </ApolloProvider>
    )
}

serviceWorker.unregister();