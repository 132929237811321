import React, {FunctionComponent, useState} from "react";
import {
    REGISTRATION_ADDRESS,
    REGISTRATION_CITY,
    REGISTRATION_CLEAR_STATE,
    REGISTRATION_COMPANY_DETAILS,
    REGISTRATION_COMPANY_DIC,
    REGISTRATION_COMPANY_IC_DPH,
    REGISTRATION_COMPANY_ICO,
    REGISTRATION_COMPANY_NAME,
    REGISTRATION_EMAIL,
    REGISTRATION_FIRST_NAME,
    REGISTRATION_LAST_NAME,
    REGISTRATION_MEMBERSHIP,
    REGISTRATION_PASSWORD,
    REGISTRATION_STATE,
    REGISTRATION_TELEPHONE,
    REGISTRATION_ZIPCODE,
    VALIDATE_REGISTRATION
} from "../../redux/actions/action_types";
import {CheckoutBooleanField, CheckoutInputField, CheckoutSelectField, ErrorField} from "../eshop/Checkout";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import axios from "axios";
import {COMPANY_NAME_ALT, BACKEND_URL} from "../../config";
import {PageNotification} from "../notification/PageNotification";
import {canSubmit} from "../../redux/reducers/checkout";
import {SpinnerLoader} from "../loading/SpinnerLoader";
import {getDefaultSupportedCountries} from "../../redux/reducers";
import {LOGIN_ROUTE_TO} from "../../app";

interface Props {

}

export const UserRegistration: FunctionComponent<Props> = () => {

    const dispatch = useDispatch();
    const registration = useSelector((state: any) => state.registration);
    const [submittingRegistration, setSubmittingRegistration] = useState<boolean>(false);
    const [emailAlreadyUsed, setEmailAlreadyUsed] = useState<boolean>(false);
    const [registrationSuccessful, setRegistrationSuccessful] = useState<boolean>(false);

    return (
        <div className={"min-h-full bg-gray-50"}>
            <div className="flex flex-col justify-center py-12 sm:px-6 lg:px-8">

                {!registrationSuccessful ?
                    <>
                        <div className="sm:mx-auto sm:w-full sm:max-w-md">
                            <a href={"/"}>
                                <img
                                    className="mx-auto h-12 w-auto"
                                    src={"/logo.png"}
                                    alt={COMPANY_NAME_ALT}
                                />
                            </a>
                            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Registračný
                                formulár</h2>
                            <p className="mt-2 text-center text-sm text-gray-600"></p>
                        </div>

                        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-3xl">
                            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                                <form className="lg:grid lg:grid-cols-1 lg:gap-x-12 xl:gap-x-16">
                                    <div>
                                        <div>
                                            <h2 className="text-lg font-medium text-gray-900">Osobné údaje</h2>

                                            <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                                                <CheckoutInputField field={registration.email} autoComplete={"email"}
                                                                    titleName={"Email"} type={"email"} required={true}
                                                                    inputType={"email"}
                                                                    dispatch={{
                                                                        dispatchMethod: dispatch,
                                                                        typeName: REGISTRATION_EMAIL
                                                                    }}/>
                                                <CheckoutInputField field={registration.password} autoComplete={"email"}
                                                                    titleName={"Heslo"} type={"password"}
                                                                    required={true}
                                                                    inputType={"password"}
                                                                    dispatch={{
                                                                        dispatchMethod: dispatch,
                                                                        typeName: REGISTRATION_PASSWORD
                                                                    }}/>
                                                <CheckoutInputField field={registration.firstName}
                                                                    autoComplete={"given-name"}
                                                                    titleName={"Meno"} type={"first-name"}
                                                                    required={true}
                                                                    dispatch={{
                                                                        dispatchMethod: dispatch,
                                                                        typeName: REGISTRATION_FIRST_NAME
                                                                    }}/>
                                                <CheckoutInputField field={registration.lastName}
                                                                    autoComplete={"last-name"}
                                                                    titleName={"Priezvisko"} type={"first-name"}
                                                                    required={true}
                                                                    dispatch={{
                                                                        dispatchMethod: dispatch,
                                                                        typeName: REGISTRATION_LAST_NAME
                                                                    }}/>
                                                <CheckoutInputField field={registration.telephone}
                                                                    autoComplete={"telephone"}
                                                                    titleName={"Telefón"} type={"telephone"}
                                                                    placeHolder={"Číslo v tvare 0940620000"}
                                                                    required={true}
                                                                    dispatch={{
                                                                        dispatchMethod: dispatch,
                                                                        typeName: REGISTRATION_TELEPHONE
                                                                    }}/>

                                                {/*<CheckoutInputField field={registration.membershipIdentifier}
                                                                    autoComplete={"membership-identifier"}
                                                                    titleName={"Klubový identifikátor"} type={"identifier"}
                                                                    required={false}
                                                                    dispatch={{
                                                                        dispatchMethod: dispatch,
                                                                        typeName: REGISTRATION_MEMBERSHIP
                                                                    }}/>*/}
                                            </div>
                                            <div className={"mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4"}>
                                                <CheckoutBooleanField field={registration.companyDetails}
                                                                      autoComplete={"company-details"}
                                                                      titleName={"Nakupujem na firmu"}
                                                                      type={"company-details"}
                                                                      dispatch={{
                                                                          dispatchMethod: dispatch,
                                                                          typeName: REGISTRATION_COMPANY_DETAILS
                                                                      }}/>

                                            </div>
                                            {registration.companyDetails.value ?
                                                <div
                                                    className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4 mb-12">
                                                    <CheckoutInputField field={registration.companyName}
                                                                        autoComplete={"company-name"}
                                                                        titleName={"Firma"} type={"company-name"}
                                                                        required={false}
                                                                        dispatch={{
                                                                            dispatchMethod: dispatch,
                                                                            typeName: REGISTRATION_COMPANY_NAME
                                                                        }}/>
                                                    <CheckoutInputField field={registration.companyIco}
                                                                        autoComplete={"company-ico"}
                                                                        titleName={"IČO"} type={"company-ico"}
                                                                        required={false}
                                                                        dispatch={{
                                                                            dispatchMethod: dispatch,
                                                                            typeName: REGISTRATION_COMPANY_ICO
                                                                        }}/>
                                                    <CheckoutInputField field={registration.companyDic}
                                                                        autoComplete={"company-dic"}
                                                                        titleName={"DIČ"} type={"company-dic"}
                                                                        required={false}
                                                                        dispatch={{
                                                                            dispatchMethod: dispatch,
                                                                            typeName: REGISTRATION_COMPANY_DIC
                                                                        }}/>
                                                    <CheckoutInputField field={registration.companyIcDph}
                                                                        autoComplete={"company-icDph"}
                                                                        titleName={"IČ DPH"} type={"company-icDph"}
                                                                        required={false}
                                                                        dispatch={{
                                                                            dispatchMethod: dispatch,
                                                                            typeName: REGISTRATION_COMPANY_IC_DPH
                                                                        }}/>
                                                </div> : null}
                                            <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                                                <CheckoutInputField field={registration.address}
                                                                    autoComplete={"address"}
                                                                    titleName={"Adresa"} type={"address"}
                                                                    required={true}
                                                                    dispatch={{
                                                                        dispatchMethod: dispatch,
                                                                        typeName: REGISTRATION_ADDRESS
                                                                    }}/>
                                                <CheckoutInputField field={registration.city} autoComplete={"city"}
                                                                    titleName={"Mesto"} type={"city"} required={true}
                                                                    dispatch={{
                                                                        dispatchMethod: dispatch,
                                                                        typeName: REGISTRATION_CITY
                                                                    }}/>
                                                <CheckoutSelectField field={registration.state} autoComplete={"state"}
                                                                     titleName={"Krajina"} type={"state"}
                                                                     required={true}
                                                                     options={getDefaultSupportedCountries()}
                                                                     dispatch={{
                                                                         dispatchMethod: dispatch,
                                                                         typeName: REGISTRATION_STATE
                                                                     }}/>
                                                <CheckoutInputField field={registration.zipcode}
                                                                    autoComplete={"zip-code"}
                                                                    titleName={"PSČ"} type={"zip-code"} required={true}
                                                                    dispatch={{
                                                                        dispatchMethod: dispatch,
                                                                        typeName: REGISTRATION_ZIPCODE
                                                                    }}/>
                                            </div>
                                            <button onClick={(e) => {
                                                e.preventDefault();

                                                if (submittingRegistration) {
                                                    return;
                                                }

                                                setEmailAlreadyUsed(false);
                                                dispatch({type: VALIDATE_REGISTRATION});

                                                if (canSubmit(registration)) {
                                                    setSubmittingRegistration(true);

                                                    let userObject: any = {
                                                        username: registration.email.value,
                                                        email: registration.email.value,
                                                        password: registration.password.value,
                                                        role: "Public",
                                                        userDetails: {
                                                            firstName: registration.firstName.value,
                                                            lastName: registration.lastName.value,
                                                            address: registration.address.value,
                                                            city: registration.city.value,
                                                            zipcode: registration.zipcode.value,
                                                            telephone: registration.telephone.value,
                                                            email: registration.email.value,
                                                            state: registration.state.value
                                                        },
                                                    }

                                                    if (registration.companyDetails.value) {
                                                        userObject = {
                                                            ...userObject,
                                                            companyDetails: {
                                                                name: registration?.companyName?.value,
                                                                ico: registration?.companyIco?.value,
                                                                dic: registration?.companyDic?.value,
                                                                icDph: registration?.companyIcDph?.value
                                                            }
                                                        }
                                                    }

                                                    if (registration?.membershipIdentifier?.value) {
                                                        userObject = {
                                                            ...userObject,
                                                            membershipIdentifier: registration.membershipIdentifier.value
                                                        }
                                                    }

                                                    axios
                                                        .post(BACKEND_URL + 'api/auth/local/register', userObject)
                                                        .then(response => {
                                                            if (response.data.user && response.data.user.id) {
                                                                dispatch({type: REGISTRATION_CLEAR_STATE});
                                                                setRegistrationSuccessful(true);
                                                            }
                                                        })
                                                        .catch(error => {
                                                            console.log('An error occurred:', error.response);
                                                            if (error.response.data.error.message === "Email or Username are already taken") {
                                                                setEmailAlreadyUsed(true);
                                                            }
                                                        }).finally(() => {
                                                        setSubmittingRegistration(false);
                                                    });
                                                }
                                            }}
                                                    className={"mx-auto mt-6 flex rounded-md border border-transparent px-6 py-3 text-base font-medium text-white shadow-sm bg-btn-main hover:bg-btn-main-hover"}>
                                                Registrovať
                                                {submittingRegistration ?
                                                    <SpinnerLoader classname={"ml-2 w-5 h-5"}/> : null}
                                            </button>

                                            {emailAlreadyUsed ?
                                                <ErrorField className={"text-center"}
                                                            error={"Zadaný email sa už používa"}/> : null}

                                            <p className={"mt-4 font-normal text-center text-sm text-black"}>
                                                Registráciou súhlasite so <Link target="_blank"
                                                                                className={"underline font-bold"}
                                                                                to={"/sukromie"}>spracovaním
                                                osobných údajov</Link>.
                                            </p>

                                            <div
                                                className="mt-6 flex justify-center text-center text-sm text-gray-500">
                                                <p>
                                                    máte už účet?{' '}
                                                    <Link
                                                        to={LOGIN_ROUTE_TO}
                                                        type="button"
                                                        className="font-medium text-btn-main hover:text-btn-main-hover">
                                                        Prihláste sa<span aria-hidden="true"> &rarr;</span>
                                                    </Link>
                                                </p>
                                            </div>
                                            <div
                                                className="mt-4 flex justify-center text-center text-sm text-gray-500">
                                                <p>
                                                    naspäť na{' '}
                                                    <Link
                                                        to={"/"}
                                                        type="button"
                                                        className="font-medium text-btn-main hover:text-btn-main-hover">
                                                        Úvodnú stránku<span aria-hidden="true"> &rarr;</span>
                                                    </Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </> :
                    <PageNotification title={"Registrácia bola úspešná"} linkDescription={"Úvodnú stránku"} link={"/"}
                                      additionalLink={{link: LOGIN_ROUTE_TO, description: "Prihlásiť sa", prefix: ""}}
                                      description={"Vaša registrácia bola úspešná! Skôr než začnete používať účet, prosím potvrďte svoju emailovú adresu. Potvrdzovací odkaz bol poslaný na váš email."}/>


                }
            </div>
        </div>
    )
}
