import {Currency} from "../../../../types/Common";
import React, {FunctionComponent} from "react";
import {RadioGroup} from "@headlessui/react";
import {nanoid} from "nanoid";
import {classNames, join} from "../../../../utils";
import {roundNumber} from "../../../../utils/EshopUtils";

interface VolumeOptionProps {
    variation: any;
    selectedVariation: any;
    wholesalePrice: any;
    currency: Currency;
}

interface DiscountProps {
    discount: any
}

const Discount: FunctionComponent<DiscountProps> = ({discount}) => {
    return (
        <span key={nanoid()}
              className={"w-full h-full text-xs text-center inline-flex font-medium items-center px-1 py-0.5 rounded break-words bg-nav-background"}>Zľava {discount}%</span>
    )
}

export const VolumeOption: FunctionComponent<VolumeOptionProps> = ({
                                                                       variation,
                                                                       selectedVariation,
                                                                       wholesalePrice,
                                                                       currency
                                                                   }) => {

    function findHighestDiscount(variation: any) {
        if (variation.price?.discounts?.data?.length > 0) {
            const discounts = variation.price.discounts.data.map((discount: any) => discount?.attributes?.percentage > 0 ? discount.attributes.percentage : 0);
            if (discounts?.length > 0) {
                return Math.max(...discounts);
            }
        }
        return null;
    }

    if (!variation) {
        return null;
    }

    const discount = findHighestDiscount(variation);

    return (
        <RadioGroup.Option
            key={nanoid()}
            value={variation}
            disabled={variation.stockQuantity < 1}
            className={() =>
                classNames(
                    variation.stockQuantity > 0
                        ? 'bg-white shadow-sm text-gray-900 cursor-pointer'
                        : 'bg-gray-50 text-gray-200 cursor-not-allowed',
                    selectedVariation.id === variation.id ? 'ring ring-2 ring-btn-main' : 'border border-1',
                    'group relative rounded-md py-3 px-4 flex items-center justify-center text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1 min-h-[4rem]'
                )
            }
        >
            {({active, checked}) => (
                <>
                    <RadioGroup.Label as="div"
                                      className={"flex flex-col lowercase"}>
                        {
                            variation.volume ?
                                <p className={"mb-0.5"}>
                                    {join([variation.volume.amount, variation.volume.unit?.data?.attributes?.code, variation.volume.additionalParameter], ' ')}
                                </p> : null
                        }
                        <p className={"font-bold"}>{roundNumber(wholesalePrice ? variation.price.amountWholesale : variation.price.amount)} {currency.name}</p>
                        {
                            discount ? <Discount discount={discount}/> : null
                        }
                    </RadioGroup.Label>
                    {variation.stockQuantity > 0 ? (
                        <span
                            className={classNames(
                                active ? 'border' : 'border-2',
                                checked ? 'border-indigo-500' : 'border-transparent',
                                'absolute -inset-px rounded-md pointer-events-none'
                            )}
                            aria-hidden="true"
                        />
                    ) : (
                        <span
                            aria-hidden="true"
                            className="absolute -inset-px rounded-md border-2 border-gray-200 pointer-events-none"
                        >
                                            <svg
                                                className="absolute inset-0 w-full h-full text-gray-200 stroke-2"
                                                viewBox="0 0 100 100"
                                                preserveAspectRatio="none"
                                                stroke="currentColor"
                                            >
                                              <line x1={0} y1={100} x2={100} y2={0} vectorEffect="non-scaling-stroke"/>
                                            </svg>
                                          </span>
                    )}
                </>
            )}
        </RadioGroup.Option>
    )
}