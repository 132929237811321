import {gql} from "@apollo/client";

export const GQL_HEADER_FOOTER = gql`
query HeaderFooter {
  navigation {
    data {
      id
      attributes {
        highlightedLink {
          name
          link
        }
        links {
          id
          name
          link
          icon {
            data {
              id
              attributes {
                url
                alternativeText
              }
            }
          }
        }
        logo {
          data {
            id
            attributes {
              url
              alternativeText
            }
          }
        }
        openingHours
      }
    }
  }
  footer {
    data {
      id
      attributes {
        logo {
          data {
            id
            attributes {
              url
              alternativeText
            }
          }
        }
        credits
        socialLinks {
          id
          header
          links {
            name
            link
            icon {
              data {
                id
                attributes {
                  url
                  alternativeText
                }
              }
            }
          }
        }
        links {
          id
          links {
            id
            name
            link
          }
          header
        }
      }
    }
  }
}
`

export const GQL_MAIN = gql`
query Main {
  main{
    data{
      attributes{
        header
        description
        images{
          data{
            attributes{
              alternativeText
              url
            }
          }
        }
        button{
          link
          nameEnabled
        }
      }
    }
  }
  priceList {
    data {
      attributes {
        heading {
          heading
          subHeading
          description
        }
        categoryFeatures {
          features {
            heading
            description
            amount {
              price {
                amount
              }
              prefixFrom
            }
          }
          category
        }
      }
    }
  }
  gallery {
    data {
      attributes {
        heading {
          heading
          subHeading
          description
        }
        categoryItems {
          category
          images {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          videos {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
        }
      }
    }
  }
  team {
    data {
      attributes {
        heading {
          heading
          subHeading
          description
        }
        profiles {
          fullName
          organization
          description
          photo {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          socialLinks {
            name
            link
            icon {
              data {
                attributes {
                  url
                  alternativeText
                }
              }
            }
          }
        }
      }
    }
  }
}
`

export const GQL_PRIVACY = gql`
query Privacy{
  config{
    data{
      attributes{
        privacy
      }
    }
  }
}
`

export const GQL_GENERAL_BUSINESS_RULES = gql`
query Privacy{
  config{
    data{
      attributes{
        generalBusinessRules
      }
    }
  }
}
`

export const GQL_DELIVERIES_PAYMENTS = gql`
query DeliveriesPayments {
  deliveries {
    data {
      id
      attributes {
        name
        description
        price
        method
        discounts {
          data {
            id
            attributes {
              name
              validFrom
              validUntil
              applyFrom
              percentage
            }
          }
        }
        possiblePaymentMethods(sort: "id:asc") {
          data {
            id
            attributes {
              name
              description
              price
              method
              discounts {
                data {
                  id
                  attributes {
                    name
                    applyFrom
                    validFrom
                    validUntil
                    percentage
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`

export const GQL_CATEGORIES = gql`
query Categories {
  productNavigations {
    data {
      id
      attributes {
        order
        parentCategory {
          data {
            id
          }
        }
        category {
          data {
            id
            attributes {
              name
              description
              slug
              products {
                data {
                  id
                }
              }
              img {
                data {
                  id
                  attributes {
                    url
                    alternativeText
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  config {
    data {
      id
      attributes {
        linkToWhenPartOf {
          data {
            id
            attributes {
              name
              description
              slug
            }
          }
        }
      }
    }
  }
}
`

export const GQL_PRODUCTS = gql`
query ProductsAndFilters($page: Int!, $pageSize: Int!, $arrayId: [ID]) {
  products(
    pagination: { page: $page, pageSize: $pageSize }
    filters: { categories: { id: { in: $arrayId } } }
  ) {
    meta {
      pagination {
        total
      }
    }
    data {
      id
      attributes {
        slug
        categories {
          data {
            id
          }
        }
        images {
          data {
            id
            attributes {
              formats
              url
              alternativeText
            }
          }
        }
        contains {
          data {
            id
            attributes {
              slug
              name
            }
          }
        }
        partOf {
          data {
            id
            attributes {
              slug
              name
            }
          }
        }
        description
        shortDescription
        name
         documents {
          data {
            id
            attributes {
              url
              name
            }
          }
        }
        variations {
          id
          price {
            id
            amount
            amountWholesale
            discounts {
              data {
                id
                attributes {
                  percentage
                }
              }
            }
          }
          stockQuantity
          variationIdentifier
          color {
            data {
              id
              attributes {
                hexCode
                name
                img {
                  data {
                    id
                    attributes {
                      url
                      alternativeText
                    }
                  }
                }
              }
            }
          }
          volume {
            id
            amount
            additionalParameter
            unit {
              data {
                id
                attributes {
                  code
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const GQL_PRODUCT_BY_SLUG = gql`
query ProductBySlug($slug: String!) {
  findSlug(modelName: "product", slug: $slug) {
    ... on ProductEntityResponse {
      data {
        id
        attributes {
          images {
            data {
              id
              attributes {
                formats
                url
                alternativeText
              }
            }
          }
          documents {
            data {
              id
              attributes {
                url
                name
              }
            }
          }
          fullDescription
          description
          shortDescription
          name
          slug
          contains {
            data {
              id
              attributes {
                slug
                name
              }
            }
          }
          partOf {
            data {
              id
              attributes {
                slug
                name
              }
            }
          }
          variations(sort: "stockQuantity:desc") {
            id
            price {
              id
              amount
              amountWholesale
              discounts {
                data {
                  id
                  attributes {
                    percentage
                  }
                }
              }
            }
            stockQuantity
            variationIdentifier
            color {
              data {
                id
                attributes {
                  hexCode
                  name
                  img {
                    data {
                      id
                      attributes {
                        url
                        alternativeText
                      }
                    }
                  }
                }
              }
            }
            volume {
              id
              amount
              additionalParameter
              unit {
                data {
                  id
                  attributes {
                    code
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`

export const GQL_SPECIFIC_PRODUCTS = gql`
query getProducts($arrayId: [ID]) {
  products(filters: { id: { in: $arrayId } }) {
    data {
      id
      attributes {
        name
        slug
        documents {
          data {
            id
            attributes {
              url
              name
            }
          }
        }
        images {
          data {
            id
            attributes {
              formats
              alternativeText
              url
            }
          }
        }
        variations {
          id
          stockQuantity
          volume {
            id
            amount
            additionalParameter
            unit {
              data {
                id
                attributes {
                  code
                }
              }
            }
          }
          price {
            id
            amount
            amountWholesale
            discounts {
              data {
                id
                attributes {
                  percentage
                }
              }
            }
          }
          color {
            data {
              id
              attributes {
                name
                hexCode
                img {
                  data {
                    id
                    attributes {
                      url
                      alternativeText
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const GQL_CHECK_CATEGORY_SLUG = gql`
query CheckCategoryBySlug($slug: String!) {
  findSlug(modelName: "category", slug: $slug) {
    ... on CategoryEntityResponse {
      data {
        id
      }
    }
  }
}  
`;

export const GQL_FIND_PRODUCT_BY_NAME = gql`
query findProductByName($text: String!) {
  products(
    pagination: { limit: 5 }
    filters: {
      or: [{ name: { containsi: $text } }, { slug: { containsi: $text } }]
    }
  ) {
    data {
      attributes {
        name
        slug
        images {
          data {
            attributes {
              url
              alternativeText
              formats
            }
          }
        }
      }
    }
  }
}
`